import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import IconButton from '@mui/material/IconButton';
import { useStaticQuery, Link, graphql } from "gatsby"
import * as appbarStyle from './postbar.module.css'
import Navigation from './navigation'

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide 
      appear={false} 
      direction="down" 
      in={!trigger}
      className={appbarStyle.bar}
    >
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function ScrollHideAppBar(props) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )



  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <HideOnScroll >
        <AppBar>
          <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => window.history.back()}//返回按钮
            >
                <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h6" component="div">
                {data.site.siteMetadata.title}
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  );
}