import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
//import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ScrollHideAppBar from '../components/postbar';
//import { Link } from 'gatsby-theme-material-ui';
import BackToTop from '../components/backtotop';
import Container from '../components/container';
//import ProTip from '../components/pro-tip';
// function MadeWithLove() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {`Built with love by the `}
//       <Link color="inherit" href="https://material-ui.com/">
//         Material-UI
//       </Link>
//       {` team.`}
//     </Typography>
//   );
// }


export default function App() {
  return (
      <React.Fragment>
      <CssBaseline />
        <ScrollHideAppBar />
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <Box sx={{ my: 2 }}>
          {[...new Array(12)]
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
            )
            .join('\n')}
        </Box>
      </Container>
        <BackToTop />
    </React.Fragment>
  );
}
